import { Livewire } from "../../../vendor/livewire/livewire/dist/livewire.esm";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

/**
 * Init Photoswipe  Lightbox and Gallery
 */
export function initGallery() {
    const components = Livewire.getByName("partials.gallery");
    const lightbox = new PhotoSwipeLightbox({
        gallery: `#${components[0]?.$el.id}`,
        children: "a",
        pswpModule: () => import("photoswipe"),
    });
    lightbox.init();
}
