import { Livewire } from "../../../vendor/livewire/livewire/dist/livewire.esm";

window.dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}

export function grantConsent() {
    Livewire.on("consent-update", (event) => {
        let consentData = event.consents;
        if (consentData) {
            gtag("consent", "update", consentData);
        }
    });
}

export async function initGoogleAnalytics() {
    Livewire.on("consent-default", async (event) => {
        let data = event.consents;
        gtag("consent", "default", data);
    });
}
