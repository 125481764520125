import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Flip } from "gsap/Flip";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Flip);

/**
 * Smooth Scroll To Element With GSAP
 * @param element
 * @param offset
 */
export function scrollTo(element, offset = 50) {
    gsap.to(window, {
        duration: 1,
        scrollTo: {
            y: element,
            offsetY: offset,
        },
    });
}

/**
 * Navigate Or Scroll Depending on Type
 * Of Link Or Button
 * @param el
 * @param directive
 * @param cleanup
 */
export function onNavigate({ el, directive, cleanup }) {
    const element = directive.expression;
    const onPress = (e) => {
        if (!element) {
            e.preventDefault();
            e.stopImmediatePropagation();
        } else {
            if (element === "top") {
                scrollTo("0", 0);
            } else {
                scrollTo(element);
            }
        }
    };
    el.addEventListener("click", onPress, { capture: true });
    cleanup(() => el.removeEventListener("click", onPress));
}

/**
 * Scroll To Top Button
 */
export function initScrollBtn() {
    const scrollToTop = document.getElementById("scroll-to-top");
    const btn = scrollToTop.firstElementChild;
    gsap.to(scrollToTop, {
        y: 0,
        autoAlpha: 1,
        scrollTrigger: {
            start: "100px",
            toggleActions: "play none reverse none",
            onToggle: (self) => {
                if (self.isActive) {
                    scrollToTop.classList.remove("hidden");
                } else {
                    scrollToTop.classList.add("hidden");
                }
            },
        },
    });
    if (btn) {
        btn.addEventListener("click", () => scrollTo(0, 0), { capture: true });
    }
}

/**
 * Header Animation
 */
export function headerAnimation() {
    const header = document.getElementById("nav");
    const links = document.querySelectorAll("nav a");
    const color = window.location.pathname.includes("cookie-policy")
        ? "#030712"
        : "#f9fafb";
    if (header) {
        let timeline = gsap.timeline({ duration: 0.5, paused: true });
        const navAnimate = timeline.fromTo(
            header,
            { backgroundColor: "transparent" },
            { backgroundColor: "rgba(255,255,255,0.5)" },
        );
        const linksAnimate = (targets) => {
            return navAnimate.fromTo(
                targets,
                { color },
                { color: "#030712" },
                "-=1",
            );
        };
        ScrollTrigger.batch(links, {
            start: "clamp(top=+200px center)",
            onEnter: (targets) => linksAnimate(targets).play(),
            onLeaveBack: (targets) => linksAnimate(targets).reverse(),
        });
    }
}

/**
 * Set Current Active Link
 */
export function setActiveLink() {
    const pagePath = window.location.pathname.substring(1);
    setActive(pagePath);
}

/**
 *
 */
export function onScrollSections() {
    const sections = gsap.utils.toArray(".section");
    const sectionHash = window.location.hash.substring(1);
    sections.forEach((section) => {
        if (sectionHash === section.id && !sectionHash.includes("home")) {
            setTimeout(() => scrollTo(`#${section.id}`, -50), 300);
        }
        ScrollTrigger.create({
            trigger: section,
            start: "top top",
            end: "bottom",
            onUpdate: (self) => setActive(self.trigger.id),
        });
    });
}

export function setActive(id) {
    const buttons = gsap.utils.toArray("nav a");
    const active = document.querySelector(".active-nav");
    active.classList.remove("opacity-0");
    buttons.forEach((btn) => {
        const path = btn.getAttribute("href").substring(1);
        if (path === id) {
            btn.prepend(active);
            const state = Flip.getState(active);
            Flip.from(state, {
                duration: 0.5,
                absolute: true,
                ease: "elastic.out(1,0.5)",
            });
            gsap.to(active, {
                opacity: 1,
                delay: 0.6,
                duration: 0.5,
            });
        }
    });
}
