import { Livewire } from "../../../vendor/livewire/livewire/dist/livewire.esm";
import { initGallery } from "./gallery.js";
import {
    headerAnimation,
    initScrollBtn,
    onNavigate,
    onScrollSections,
    setActiveLink,
} from "./js-functions.js";
import { grantConsent } from "./google-ga.js";

/**
 * Init Custom Javascript LiveWire Helpers
 */
export default function () {
    document.addEventListener("livewire:init", () => {
        Livewire.directive("to", onNavigate);
    });
    document.addEventListener("livewire:navigated", () => {
        grantConsent();
        initGallery();
        initScrollBtn();
        headerAnimation();
        setActiveLink();
        onScrollSections();
    });
}
