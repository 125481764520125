import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Flip } from "gsap/Flip";

gsap.registerPlugin(ScrollTrigger, Flip);

const languages = [
    {
        id: "en",
        locale: "en",
        icon: "icons/usa.svg",
    },
    {
        id: "bs",
        locale: "bs",
        icon: "icons/bs.svg",
    },
];

export default function (Alpine) {
    Alpine.store("menu", {
        open: false,
        toggleMenu() {
            this.open = !this.open;
        },
    });
    Alpine.data("tabs", (selectedKey) => ({
        selectedKey,
        setSelectedKey(key) {
            this.selectedKey = key;
        },
        tabBtn: {
            ["@click"](e) {
                const active = document.querySelector(".active-tab");
                const state = Flip.getState(active);
                e.target.prepend(active);
                Flip.from(state, {
                    duration: 0.5,
                    absolute: true,
                    ease: "elastic.out(1,0.5)",
                });
                this.setSelectedKey(e.target.id);
            },
        },
    }));
}
