import "./bootstrap";
import "unfonts.css";
import { livewire_hot_reload } from "virtual:livewire-hot-reload";
import {
    Alpine,
    Livewire,
} from "../../vendor/livewire/livewire/dist/livewire.esm";
import alpineHelpers from "./utils/alpine-helpers.js";
import livewireHelpers from "./utils/livewire-helpers.js";

import.meta.glob(["/storage/app/*/**"]);
livewire_hot_reload();

Alpine.plugin(alpineHelpers);
livewireHelpers();
Livewire.start();
